import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { Loader } from '../shared/Loader'
import Amplitude from 'src/utils/amplitudeHelper'

const StyledModal = styled(Modal)`
  .modal-content {
    background: transparent;
    border: none;
  }
  .iframe-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      background: #000;
    }
  }
  .modal-header {
    padding: 8px;
    border: none;
    background: rgba(0, 0, 0, 0);
   
    button.close {
      opacity: 1;
      padding: 4px auto !important;
      z-index: 100;
      position: absolute;
      top: -3%;
      right: -3%;
      span {
        font-weight: 400;
        font-size: 48px;
        color: #f8c605;
        text-shadow: none;
      }
    }
  }
`

export default function WelcomeVideoModal({ showModal, hideModal }) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    Amplitude.click('Welcome Video')
  }, [])

  return (
    <StyledModal
      show={showModal}
      onHide={hideModal}
      size="xl"
      aria-labelledby="welcome-video"
      centered
    >
      <Modal.Header closeButton />

      <Modal.Body>
        <div className="iframe-container" >
          {loading && <Loader fullPage={false} loading />}
          <iframe
            width="100%"
            height="100%"
            title="Welcome Video"
            src="https://iframe.dacast.com/vod/1b1f6ca6-faef-317f-74bf-a4ce68f8e2d6/f9ce0a74-609a-4359-8549-51fdd2f3fcd3?autoplay=1"
            allow="autoplay; fullscreen; encrypted-media"
            allowFullScreen
            allowtransparency="true"
            frameBorder="0"
            onLoad={() => {
              setLoading(false)
            }}
          />
        </div>
      </Modal.Body>
    </StyledModal>
  )
}
