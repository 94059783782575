import AOS from 'aos'
import React, { useEffect, useMemo } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import config from './config'
import routes from './routes'

import { PreCache } from './containers/PreCache'

// import ReactGA from 'react-ga4'

import InstiRegistration from './pages/registration/InstiRegistration'
import PostRegisterPage from './pages/registration/PostRegisterPage'
import PreRegisterPage from './pages/registration/PreRegisterPage'

declare global {
  interface Window {
    chaport: any
  }
}
// ReactGA.initialize('G-YTL11PFZRT')

function App() {
  const location = useLocation()

  useEffect(() => {
    document.title = config.eventName

    AOS.init({
      easing: 'ease-in-out',
      once: true,
      offset: 50,
      anchorPlacement: 'top-bottom',
    })
  }, [])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      // ReactGA.send({
      //   hitType: 'pageview',
      //   page: location.pathname + location.search,
      // })
    }
  }, [location])

  return useMemo(() => {
    return (
      <div id="innerBody">
        <PreCache deviceType={'desktop'}>
          <Switch>
            <Route exact path={routes.root} component={() => <PreRegisterPage />} />
            {/* <Route exact path={routes.preReg} component={() => <PreRegisterPage />} />
            <Route exact path={routes.reg} component={InstiRegistration} />
            <Route exact path={routes.postReg} component={() => <PostRegisterPage />} /> */}
          </Switch>
        </PreCache>
      </div>
    )
  }, [])
}

export default App
