import { COLORS } from 'src/styles/theme'
import styled from 'styled-components'

export const StyledEntrySelectionModal = styled.div`
  z-index: 2000; /* bootstrap nav has 1030 */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  .box-dialog {
    border-radius: 8px;
    width: 90%;
    max-height: 70vh;
    max-width: 400px;
    background-color: rgba(255, 255, 255, 1);
    text-align: center;
    padding: 20px;
    border: 1px solid #F8C605;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      padding-left: 1em;
      font-weight: 700;
      color: #FFAF2A;
    }

    a {
      display: block;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      color: #000;
      text-decoration: none;
      margin-top: 12px;
      background: #F8C605;
      padding: 8px;
      border-radius: 8px;
      width: 100%;

      span {
        margin-right: 8px;
        visibility: hidden;
      }

      &:hover {
        color: #fff;
        span {
          visibility: visible;
        }
      }
    }
  }
`
