import React from 'react'

import RegistrationTemplate from './RegistrationTemplate'

// const PreRegSection = styled.section`
//   width: 100%;
//   text-align: center;
//   border-left: 2px solid rgba(0, 0, 0, 0.3);
//   min-height: 200px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;

//   ${SIZES.mobile} {
//     border-left: none;
//   }

//   h3 {
//     font-weight: 600;
//   }
//   .btn {
//     border-radius: 10px;
//     width: 100%;
//     max-width: 400px;
//     margin-top: 0.8em;
//   }

//   .disclaimer {
//     max-width: 400px;
//   }
// `

export default function PreRegisterPage() {
  return (
    <RegistrationTemplate showCountdown showButton>
      <></>
    </RegistrationTemplate>
  )
}
