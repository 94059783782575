import React, { useCallback, useEffect, useState } from 'react'
import { Loader } from 'src/components/shared/Loader'
import { cacheWebImages } from '../utils/cacheHelper'

import regBg from '../images/register/register_bg.png'
import regBgMobile from '../images/register/register_bg_mobile.png'
import registerKv from '../images/register/register_visual.png'
import registerKvM from '../images/register/register_visual_mobile.png'
import registerKvSimple from '../images/register/register_visual_simple.png'
import checkIcon from '../images/register/check_icon.png'
// import infoIcon from '../images/backdrops/info-icon.png'
// import profileIcon from '../images/backdrops/speaker-icon.png'
// import playIcon from '../images/backdrops/play-icon.png'

// import lobby from '../images/backdrops/lobby.jpg'
// import auditorium from '../images/backdrops/auditorium.jpg'
// import boothBg from '../images/backdrops/booth_bg.jpg'
// import entranceArrow from '../images/backdrops/lobby-arrow.png'
// import auditoriumPoster from '../images/backdrops/auditorium-poster.jpg'
// import bannerM from '../images/backdrops/mobile-banner.jpg'
// import helpdeskStand from '../images/backdrops/helpdesk_stand.png'
// import speakersStand from '../images/backdrops/speakersprofile_stand.png'
// import auditoriumEntrance from '../images/backdrops/auditorium_entrance.png'
// import exhibitionEntrance from '../images/backdrops/exhibition_entrance.png'
// import ondemandEntrance from '../images/backdrops/ondemand_entrance.png'
// import galleryBg from '../images/backdrops/gallery-bg.jpg'
// import galleryMask from '../images/backdrops/galleryMask.png'

export const fixedAssets = {
  registerKv,
  registerKvM,
  registerKvSimple,
  regBg,
  regBgMobile,
  checkIcon,
  // bannerM,
  // helpdeskStand,
  // speakersStand,
  // infoIcon,
  // profileIcon,
  // boothBg,
  // playIcon,
  // galleryBg,
  // galleryMask,

  // lobby,
  // auditorium,
  // auditoriumPoster,
  // entranceArrow,
  // auditoriumEntrance,
  // exhibitionEntrance,
  // ondemandEntrance,
}

export const PreCache = ({ deviceType, children }) => {
  const [pageLoading, setPageLoading] = useState(false)

  const preloadAssets = useCallback(async () => {
    setPageLoading(true)
    await cacheWebImages(Object.values(fixedAssets))
    setPageLoading(false)
  }, [])

  useEffect(() => {
    preloadAssets()
  }, [deviceType, preloadAssets])

  return (
    <Loader loading={pageLoading} fullPage>
      <>{children}</>
    </Loader>
  )
}
