import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CurrentUserType } from 'src/ducks/auth'
import { RoutesType } from 'src/routes'
import Amplitude from 'src/utils/amplitudeHelper'
import { showGlobalModal } from '../../ducks/layout'
import { GlobalModalTypes } from '../GlobalModals/GlobalModalContainer'
import { EntrySelectionModal, MultiDestinationsType } from './EntrySelectionModal/EntrySelectionModal'
import { PinWrapper } from './styled'

export enum LobbyEntryTypes {
  'DirectRoute', // direct route string
  'MultiRoutes', // array of route strings
  'Helpdesk', // trigger showHelpDesk
  'GlobalModal', // trigger globalModal
}

interface CommonProps {
  css: string
  pinText?: string
  textCss?: string
  pinImage?: string
  entryOpenImage?: string
  entryCloseImage?: string
}

type EntryTypeProps =
  | { type: LobbyEntryTypes.DirectRoute; destination: RoutesType }
  | { type: LobbyEntryTypes.MultiRoutes; destinations: Array<MultiDestinationsType>; selectionTitle: string }
  | { type: LobbyEntryTypes.GlobalModal; modalId: GlobalModalTypes }
  | { type: LobbyEntryTypes.Helpdesk }

export type LobbyEntryProps = CommonProps & EntryTypeProps

const SplitText = (word: string) => {
  return (
    <span>
      {word.split("").map((char, index) => (
        <span aria-hidden="true" key={index} style={{animationDelay: `${(0.5 + index / 10)}s`}}>
          {char}
        </span>
      ))}
    </span>
  )
}

export const LobbyEntry = (props: LobbyEntryProps & { currentUser: CurrentUserType }) => {
  const { currentUser, css, textCss, pinText, pinImage, entryOpenImage, entryCloseImage } = props

  const [multiRoutesModal, setMultiRoutesModal] = useState<boolean>(false)

  const history = useHistory()
  const dispatch = useDispatch()

  const showHelpDesk = () => {
    // todo
    Amplitude.clickHelpDesk()
  }

  const handleEntryClick = () => {
    if (props.type === LobbyEntryTypes.DirectRoute) {
      history.push(props.destination)
    }
    if (props.type === LobbyEntryTypes.MultiRoutes) {
      setMultiRoutesModal(true)
    }
    if (props.type === LobbyEntryTypes.GlobalModal) {
      dispatch(showGlobalModal(props.modalId))
    }
    if (props.type === LobbyEntryTypes.Helpdesk) {
      showHelpDesk()
    }
  }

  return (
    <>
      {props.type === LobbyEntryTypes.MultiRoutes && props.destinations && multiRoutesModal && (
        <EntrySelectionModal
          destinations={props.destinations}
          modalTitle={props.selectionTitle}
          handleClose={() => {
            setMultiRoutesModal(false)
          }}
        />
      )}
      <PinWrapper css={css} textCss={textCss} onClick={handleEntryClick}>
        {pinText &&
          <div className='textParent'>
            <h5 className="pinText">{SplitText(pinText)}</h5>
          </div>
        }
        {pinImage && <img alt="pins" className="pinImage" src={pinImage} />}
      </PinWrapper>
    </>
  )
}
