import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyBg9f9MJvw_Oxp8OK66tlP1hY05t9AahEU",
  authDomain: "maybank-ia23.firebaseapp.com",
  databaseURL: "https://maybank-ia23-default-rtdb.firebaseio.com",
  projectId: "maybank-ia23",
  storageBucket: "maybank-ia23.appspot.com",
  messagingSenderId: "141027888835",
  appId: "1:141027888835:web:cf655adac2893912dc5c76"
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

const firebaseDb = firebase.database()
const firebaseAuth = firebase.auth()

const getDbRef = (dbRef) => {
  const envDbRef = `${process.env.NODE_ENV === 'production' ? 'production' : 'local'}/${dbRef}`
  return firebaseDb.ref(envDbRef)
}

const ChatDb = getDbRef('chat')
const HallOneDb = getDbRef('Hall_A_Question')
const HallTwoDb = getDbRef('Hall_B_Question')
const HallOneChatDb = getDbRef('Hall_A_Chat')
const HallTwoChatDb = getDbRef('Hall_B_Chat')
const NoticeDb = getDbRef('notice')
const NotificationDb = getDbRef('pushNotifications')
const PopupRef = getDbRef('LaunchPopup')
const VideoRef = getDbRef('GrowthCentre')

const getUniqueSessionDb = (uid) => getDbRef(`uniqueSession/${uid}`)
const getUserCountDb = (uid, path) => getDbRef(`userCount/${path}/${uid}`)
const getUserSessionDb = (uid) => getDbRef(`userSessions/${uid}`)
const getOnlineStatusDb = (uid) => getDbRef(`onlineStatus/${uid}`)
const getLiveCheckInSessionDb = (hallNumber) => getDbRef(`liveCheckInSession/hall-${hallNumber}`)
const getRefreshDb = (uid) => getDbRef(`shouldRefresh/${uid}`)
const getLiveSessionDb = (hallNumber) => getDbRef(`liveSession/${hallNumber}`)

export {
  firebaseDb,
  firebaseAuth,
  ChatDb,
  HallOneDb,
  HallTwoDb,
  HallOneChatDb,
  HallTwoChatDb,
  NoticeDb,
  NotificationDb,
  PopupRef,
  VideoRef,
  getUniqueSessionDb,
  getUserCountDb,
  getUserSessionDb,
  getOnlineStatusDb,
  getLiveCheckInSessionDb,
  getRefreshDb,
  getLiveSessionDb
}
