import amplitude from 'amplitude-js'
import config from '../config'

const Amplitude = {
  init: () => {
    amplitude.getInstance().init(config.API_KEYS.amplitude)
  },

  // TODO: Put all amplitude tracking functions here
  visit: (page) => {
    amplitude.getInstance().logEvent(`Visit ${page}`)
  },

  clickLaunchButton: () => {
    amplitude.getInstance().logEvent(`Click Launch Button`)
  },

  clickGuides: (url) => {
    amplitude.getInstance().logEvent(`Click Guides: ${url}`)
  },

  visitExhibition: (hallNumber) => {
    amplitude.getInstance().logEvent(`Visit Exhibition Hall`, {
      hallNumber: hallNumber,
    })
  },

  clickGalleryItem: (title) => {
    amplitude.getInstance().logEvent(`View Gallery Video`, {
      title,
    })
  },

  visitBooth: (boothName) => {
    amplitude.getInstance().logEvent(`Visit Booth`, {
      boothName: boothName,
    })
  },

  watchBoothVideo: (filename) => {
    amplitude.getInstance().logEvent(`Watch ${filename}`)
  },

  viewAbstractPresentation: (abstract, type) => {
    amplitude.getInstance().logEvent(`View Abstract Presentation`, {
      abstractId: abstract.id,
      abstractTitle: abstract.title,
      abstractType: type,
    })
  },
  
  viewGalleryVideo: (title, category) => {
    amplitude.getInstance().logEvent(`View Gallery Video`, {
      title,
      category,
    })
  },

  click: (event) => {
    amplitude.getInstance().logEvent(`Click ${event}`)
  },
  
  clickHelpDesk: () => {
    amplitude.getInstance().logEvent('Click Lobby Helpdesk')
  },

  clickLogout: () => {
    amplitude.getInstance().logEvent('Click Logout');
  },

  clickMenu: () => {
    amplitude.getInstance().logEvent('Click Menu')
  },

  watchPlayback: (id) => {
    amplitude.getInstance().logEvent('Watch Playback', {
      sessionId: id
    })
  },

  clickNotification: () => {
    amplitude.getInstance().logEvent('Click Notifications')
  },

  clickAgenda: () => {
    amplitude.getInstance().logEvent('Click Virtual Programme Modal')
  },
  
  clickSpeaker: () => {
    amplitude.getInstance().logEvent("Click Speakers' Profile Modal")
  },
  clickExhibition: () => {
    amplitude.getInstance().logEvent('Click Exhibition Entry')
  },

  clickHotButtons: (boothName, type) => {
    amplitude.getInstance().logEvent('Click Booth HotButton', {
      boothName: boothName,
      hotButtonType: type,
    })
  },

  clickHint: (boothName) => {
    amplitude.getInstance().logEvent('Click Booth Hint', {
      boothName: boothName,
    })
  },

  collectCoin: (missionId) => {
    amplitude.getInstance().logEvent('Collect Coin', {
      missionId: missionId,
    })
  },

  enterDraw: () => {
    amplitude.getInstance().logEvent('Click Enter Draw')
  },

}

export default Amplitude
