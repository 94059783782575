import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Media from 'react-media'
import { fixedAssets } from 'src/containers/PreCache'
import { FONTS, SIZES } from 'src/styles/theme'
import styled from 'styled-components'
import CountdownTimer from '../CountdownTimer'
import routes from 'src/routes'

const Page = styled.div`
  min-height: 100vh;
  padding: 0;

  background-image: url(${fixedAssets.regBg});
  background-attachment: fixed;
  background-color: #f8c605;
  background-repeat: no-repeat;
  background-size: cover;

  ${SIZES.mobile} {
    background-image: url(${fixedAssets.regBgMobile});
    background-size: cover;
  }

  .mobileKv {
    max-width: 400px;
  }

  footer {
    font-size: 13px;
    padding-bottom: 1em;
    color: #424242;
  }

  .formCol {
    min-height: 100vh;
  }

  .date {
    text-align: left;
    width: 75%;
    color: #3a3d45;
    font-weight: 500;
    font-size: 1.8rem;
    margin-top: 8px;
    margin-bottom: 0;

    ${SIZES.mobile} {
      max-width: 400px;
      width: 65%;
      font-size: 1.5rem;
      margin: 8px auto;
      margin-bottom: 2em;
    }
  }

  .formWrapper {
    background: transparent;
    padding: 2em 0;

    .card {
      border: none;
      padding: 25px 25px;
      border-radius: 15px;
      background: #efefef;
    }

    .form-label {
      font-size: 14px;
      display: block;

      .text-muted {
        font-style: italic;
        font-weight: 300;
        margin-left: 6px;
      }
    }

    .form-input {
      background: #fff;
      border-radius: 5px;
      border: none;
      width: 100%;
      height: 40px;
      padding: 0 16px;
      appearance: none;
      background-image: url('');
    }

    .select-search {
      width: 100%;
      height: 40px;
      .select-search__input {
        height: 40px;
      }
      .select-search__value,
      .select-search__input {
        background: #f2f1f9;
      }
      .select-search__option.is-selected,
      .select-search__option.is-highlighted,
      .select-search__option:not(.is-selected):hover,
      .select-search__option.is-selected:hover {
        background: #f8c60599;
      }
    }

    select:invalid,
    select option[value=''],
    textarea::placeholder,
    input::placeholder {
      font-family: ${FONTS.primary};
      font-size: 14px;
      color: #959595;
      font-weight: 300;
    }

    /*Added for browser compatibility*/
    [hidden] {
      display: none;
    }

    .privacy {
      margin: 0 auto;
      width: 100%;
      padding: 0;
      margin-top: 1.5rem;
      .form-check {
        display: flex;
        align-items: flex-start;
        padding: 0;
        font-weight: 400;

        .form-check-label {
          width: 100%;
          font-size: 14px;
          margin-left: 8px;
          a {
            text-decoration: underline;
            color: #212529;
            word-break: break-word;
          }
        }
      }
      input[type='checkbox'] {
        width: 16px;
        height: 16px;
        margin-top: 2px;
        accent-color: #f8c605;
      }
    }
  }
  .loginForm {
    width: 75%;
    margin: auto;
    ${SIZES.mobile} {
      width: 100%;
    }
  }
`

export default function RegistrationTemplate({
  children,
  showCountdown = false,
  showButton = false,
  simpleMasthead = false,
}: {
  children: React.ReactNode
  showCountdown?: boolean
  showButton?: boolean
  simpleMasthead?: boolean
}) {
  return (
    <Media query={`(max-width: ${SIZES.mobileBreakpoint})`}>
      {(matches) =>
        matches ? (
          <Page>
            <Container className="h-100">
              <div className="h-dvh py-5 d-flex flex-column justify-content-center">
                {showCountdown && <CountdownTimer />}
                {simpleMasthead ? (
                  <img src={fixedAssets.registerKvSimple} alt="event-banner" width="100%" className="mobileKv d-block mx-auto" />
                ) : (
                  <img src={fixedAssets.registerKvM} alt="event-banner" width="100%" className="mobileKv d-block mx-auto" />
                )}
                {children}
                {showButton && (
                  <div className="mx-auto text-center d-flex align-items-end flex-grow-1 pb-4">
                    <a href={routes.reg} className="disabled btn btn-warning font-weight-bold btn-lg mt-4 rounded-pill px-4">
                      Registration Closed
                    </a>
                  </div>
                )}
              </div>
            </Container>
          </Page>
        ) : (
          <Page>
            <Container className="h-100">
              <Row noGutters className="h-100">
                <Col md={6} className="formCol flex-column align-items-center justify-content-center h-100 d-flex">
                  {showCountdown && <CountdownTimer />}
                  <img src={fixedAssets.registerKv} alt="event-banner" width="95%" className="d-block mx-auto" />
                  {showButton && (
                    <div className="mr-auto">
                      <a href={routes.reg} className="disabled btn btn-warning font-weight-bold btn-lg mt-4 rounded-pill px-4">
                        Registration Closed
                      </a>
                    </div>
                  )}
                </Col>
                <Col md={6} className="formCol align-items-center justify-content-center h-100 d-flex pl-4">
                  {children}
                </Col>
              </Row>
            </Container>
            <footer className="text-center">Copyright@2024 Maybank. All rights reserved</footer>
          </Page>
        )
      }
    </Media>
  )
}
