import { isEmpty } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hideGlobalModal } from 'src/ducks/layout'
import NotificationModal from './NotificationModal'
import WelcomeVideoModal from './WelcomeVideoModal'
import ExhibitionEntryModal from './ExhibitionEntryModal'
import { SpeakersProfileModal } from './SpeakersProfileModal'
import { VirtualAgendaModal } from './VirtualAgendaModal'

export enum GlobalModalTypes {
  'Notifications',
  'Agenda',
  'Speakers',
  'EntrySelection',
  'Welcome',
}

export default function GlobalModalContainer({ currentUser }) {
  const dispatch = useDispatch()
  const { globalModal } = useSelector((state) => state.layout)
  
  const hideModal = () => {
    dispatch(hideGlobalModal())
  }

  switch (globalModal) {
    case GlobalModalTypes.Notifications:
      return <NotificationModal showModal={isEmpty(globalModal)} hideModal={hideModal} />
    case GlobalModalTypes.Agenda:
      return <VirtualAgendaModal showModal={isEmpty(globalModal)} hideModal={hideModal} />
      case GlobalModalTypes.Speakers:
      return <SpeakersProfileModal showModal={isEmpty(globalModal)} hideModal={hideModal} />
    case GlobalModalTypes.Welcome:
      return <WelcomeVideoModal showModal={isEmpty(globalModal)} hideModal={hideModal} />
    case GlobalModalTypes.EntrySelection:
      return <ExhibitionEntryModal showModal={isEmpty(globalModal)} hideModal={hideModal} />
    default:
      return <></>
  }
}
